import React from "react";
import styled from "@emotion/styled";
// utils
import { Color } from "../../utils/color";
// image
import ImgHero01 from "../../images/index_hero_01.jpg";
import ImgHero02 from "../../images/index_hero_02.jpg";
import ImgHero03 from "../../images/index_hero_03.jpg";

export const IndexHeroSection = () => {
  return (
    <SSection>
      <SHero data-hero-num="01">
        <h1>01</h1>
        <h1>サンプルテキスト</h1>
        <h1>サンプルテキスト</h1>
      </SHero>
      <SHero data-hero-num="02">
        <h1>02</h1>
        <h1>サンプルテキスト</h1>
        <h1>サンプルテキスト</h1>
      </SHero>
      <SHero data-hero-num="03">
        <h1>03</h1>
        <h1>サンプルテキスト</h1>
        <h1>サンプルテキスト</h1>
      </SHero>
    </SSection>
  );
};

const SSection = styled.section`
  width: 100%;
  height: 640px;
  position: relative;
  overflow: hidden;
`;

const SHero = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  transform-origin: left;
  animation-timing-function: ease;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform-origin: center;
  background-position: center;
  background-size: cover;
  h1 {
    color: ${Color.white};
  }
  &[data-hero-num="01"] {
    animation-name: hero01;
    background-image: url(${ImgHero01});
    transform: translateX(0%) translateY(0%);
    @keyframes hero01 {
      0% {
        transform: translateX(0%) translateY(0%);
      }
      23% {
        transform: translateX(0%) translateY(0%);
      }
      33% {
        transform: translateX(-100%) translateY(0%);
      }
      56% {
        transform: translateX(-100%) translateY(100%);
      }
      66% {
        transform: translateX(100%) translateY(100%);
      }
      90% {
        transform: translateX(100%) translateY(0%);
      }
    }
  }
  &[data-hero-num="02"] {
    animation-name: hero02;
    background-image: url(${ImgHero02});
    transform: translateX(100%) translateY(100%);
    @keyframes hero02 {
      0% {
        transform: translateX(100%) translateY(100%);
      }
      23% {
        transform: translateX(100%) translateY(0%);
      }
      33% {
        transform: translateX(0%) translateY(0%);
      }
      56% {
        transform: translateX(0%) translateY(0%);
      }
      66% {
        transform: translateX(-100%) translateY(0%);
      }
      90% {
        transform: translateX(-100%) translateY(100%);
      }
    }
  }
  &[data-hero-num="03"] {
    animation-name: hero03;
    background-image: url(${ImgHero03});
    transform: translateX(-100%) translateY(0%);
    @keyframes hero03 {
      0% {
        transform: translateX(-100%) translateY(0%);
      }
      23% {
        transform: translateX(-100%) translateY(100%);
      }
      33% {
        transform: translateX(100%) translateY(100%);
      }
      56% {
        transform: translateX(100%) translateY(0%);
      }
      66% {
        transform: translateX(0%) translateY(0%);
      }
      90% {
        transform: translateX(0%) translateY(0%);
      }
    }
  }
`;
