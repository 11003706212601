import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
// components
import { SectionHeading } from "../heading/sectionHeading";
import { ViewMoreBtn } from "../button/viewmoreBtn";
// utils
import { Color } from "../../utils/color";
import { NewsLists } from "../../utils/newsLists";

export const IndexNewsSection = () => {
  return (
    <SSection>
      <SContainer>
        <SectionHeading txt="お知らせ" />
        <SWrapper>
          {NewsLists.map(({ id, date, url, tagTxt, tagFlg, title }) => {
            return (
              <Link to={url} key={id}>
                <span>{date}</span>
                <span data-tag-type={tagFlg}>#{tagTxt}</span>
                <span>{title}</span>
              </Link>
            );
          })}
        </SWrapper>
        <SFooting>
          <ViewMoreBtn url="/news" />
        </SFooting>
      </SContainer>
    </SSection>
  );
};

const SSection = styled.section`
  padding: 4rem 0;
  border-bottom: 1px solid ${Color.gray100};
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    padding: 2rem 0;
  }
`;

const SContainer = styled.div`
  width: 96%;
  max-width: 1366px;
  margin: auto;
`;

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  a {
    padding: 1rem 0;
    display: none;
    align-items: center;
    border-bottom: 1px dashed ${Color.gray100};
    &:last-of-type {
      border-bottom: none;
    }
    /* 最新３つのみ表示 */
    &:nth-last-of-type(-n + 3) {
      display: flex;
    }
    span {
      color: ${Color.gray300};
      font-size: 0.9rem;
      line-height: 1;
      &:nth-of-type(1) {
        margin-right: 1rem;
      }
      &:nth-of-type(2) {
        font-size: 0.6rem;
        padding: 4px;
        border-radius: 4px;
        font-weight: 500;
        margin-right: 2rem;
        min-width: 64px;
        text-align: center;
        &[data-tag-type="blue"] {
          background-color: ${Color.blue};
          color: ${Color.white};
        }
        &[data-tag-type="green"] {
          background-color: ${Color.green};
          color: ${Color.gray400};
        }
        &[data-tag-type="red"] {
          background-color: ${Color.red};
          color: ${Color.white};
        }
        &[data-tag-type="yellow"] {
          background-color: ${Color.yellow};
        }
      }
      &:nth-of-type(3) {
        transition: all 0.2s;
      }
    }
    @media screen and (min-width: 1024px) {
      &:hover {
        span {
          &:nth-of-type(3) {
            color: ${Color.blue};
          }
        }
      }
    }
    @media screen and (max-width: 1023px) {
      &:active {
        span {
          &:nth-of-type(3) {
            color: ${Color.blue};
          }
        }
      }
    }
  }
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    a {
      flex-wrap: wrap;
      span {
        &:nth-of-type(3) {
          width: 100%;
          margin-top: 0.5rem;
        }
      }
    }
  }
`;

const SFooting = styled.div`
  margin-top: 2rem;
`;
